import React, { useState, useEffect, useRef } from "react";
import { QUOTES } from "./quotes.js";
import { useGame } from "./gameContext";
import { audioManager } from "./audioManager";

const GameDisplay = ({
  currentMatch,
  playerRole,
  matchOutcome,
  onMatchComplete,
  isGameActive,
}) => {
  const [countdown, setCountdown] = useState(5);
  const [showVideo, setShowVideo] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const { updateBalance, matchStatus, error, cancelSearch, transactionStatus } =
    useGame();
  const videoRef = useRef(null);
  const randomQuote = useRef(
    QUOTES[Math.floor(Math.random() * QUOTES.length)]
  ).current;

  // In GameDisplay.jsx, add this useEffect
  useEffect(() => {
    if (!isGameActive) return; // Early return inside effect instead
    if (transactionStatus === "confirmed" && countdown > 0) {
      audioManager.play(countdown === 1 ? "countdownFinal" : "countdownTick");
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setShowVideo(true);
      if (videoRef.current) {
        videoRef.current
          .play()
          .catch((e) => console.error("Video play error:", e));
      }
    }
  }, [countdown, transactionStatus, matchStatus]);

  if (!isGameActive) {
    return null;
  }

  const handleVideoEnd = async () => {
    setVideoEnded(true);
    audioManager.play(matchOutcome === playerRole ? "doubled" : "dust");

    Promise.all([
      updateBalance(),
      new Promise((resolve) => setTimeout(resolve, 3000)),
    ]).then(() => {
      if (onMatchComplete) {
        onMatchComplete();
      }
    });
  };

  // Error State
  if (error) {
    return (
      <div className="countdown-display">
        <h2>Match Error: {error}</h2>
        <p>Please try again</p>
        <button onClick={onMatchComplete} className="retry-button">
          Return to Home
        </button>
      </div>
    );
  }

  // Searching State
  if (matchStatus === "searching") {
    return (
      <div className="countdown-display">
        <h2>Searching for opponent...</h2>
        <p className="random-quote">{randomQuote}</p>
        <button
          onClick={() => {
            cancelSearch();
            onMatchComplete();
          }}
          className="cancel-button"
        >
          Cancel Search
        </button>
      </div>
    );
  }

  // Match Found & Transaction Pending State
  if (matchStatus === "found" || transactionStatus === "pending") {
    return (
      <div className="countdown-display">
        <h2>Preparing your match...</h2>
        <p className="random-quote">{randomQuote}</p>
      </div>
    );
  }

  // Transaction Confirmed but Countdown Not Started
  if (transactionStatus === "confirmed" && !showVideo) {
    return (
      <div className="countdown-display">
        <h2>Match starting in: {countdown}</h2>
        <p className="random-quote">{randomQuote}</p>
      </div>
    );
  }

  // Add this check before the final video display return
  if (!currentMatch || !currentMatch.headline) {
    return (
      <div className="countdown-display">
        <h2>Preparing your match...</h2>
        <p className="random-quote">{randomQuote}</p>
      </div>
    );
  }

  return (
    <div className="game-display">
      <h2 className="match-headline">{currentMatch.headline}</h2>

      <div className="outcome-options">
        <div className="option-container">
          <div className={`option ${playerRole === "A" ? "your-choice" : ""}`}>
            {currentMatch.playerAChoice}
          </div>
          {playerRole === "A" && <div className="you-indicator">YOU</div>}
        </div>

        <span className="vs">vs</span>

        <div className="option-container">
          <div className={`option ${playerRole === "B" ? "your-choice" : ""}`}>
            {currentMatch.playerBChoice}
          </div>
          {playerRole === "B" && <div className="you-indicator">YOU</div>}
        </div>
      </div>

      <video
        ref={videoRef}
        autoPlay
        preload="auto"
        muted
        style={{
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto",
        }}
        onEnded={handleVideoEnd}
      >
        <source src={currentMatch.videoUrl} type="video/mp4" />
      </video>
      {videoEnded && matchOutcome && (
        <div
          className={`outcome-overlay ${
            matchOutcome === playerRole ? "win" : "lose"
          }`}
        >
          <span>{matchOutcome === playerRole ? "DOUBLED" : "DUST"}</span>
        </div>
      )}
    </div>
  );
};

export default GameDisplay;
