import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import Home from "./Home.jsx";
import TokenDetail from "./TokenDetail.jsx";
import TokenCreate from "./TokenCreate.jsx";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { GameProvider } from "./gameContext.js"; // Add this import
import planktonLogo from "../assets/images/plankton.png";
import fishLogo from "../assets/images/angel-fish.png";
import whaleLogo from "../assets/images/whale.png";
require("@solana/wallet-adapter-react-ui/styles.css");

const Modal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{
          maxWidth: "500px",
          padding: "20px 30px 30px",
        }}
      >
        <button className="modal-close" onClick={onClose}>
          ×
        </button>
        {content === "how" ? (
          <div style={{ color: "white", textAlign: "center" }}>
            <h2
              style={{
                fontSize: "24px",
                marginBottom: "20px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              how it works
            </h2>

            <p
              style={{
                marginBottom: "30px",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              D.O.D lets you instantly{" "}
              <span style={{ color: "rgb(134, 239, 172)" }}>double</span> your
              memecoins through{" "}
              <span style={{ color: "rgb(134, 239, 172)" }}>fair</span>{" "}
              player-vs-player matches. every outcome is determined by{" "}
              <span style={{ color: "rgb(134, 239, 172)" }}>
                transparent blockchain mathematics
              </span>{" "}
              that anyone can verify.
            </p>

            <p
              style={{
                marginBottom: "20px",
                fontWeight: "500",
                color: "#D9D9D9",
              }}
            >
              step 1: connect your solana wallet
            </p>

            <div style={{ marginBottom: "20px" }}>
              <p
                style={{
                  marginBottom: "5px",
                  fontWeight: "500",
                  color: "#D9D9D9",
                }}
              >
                step 2: choose any memecoin and amount you want to double
              </p>
              <p style={{ fontWeight: "500", color: "#D9D9D9" }}>(or sol)</p>
            </div>

            <p
              style={{
                marginBottom: "20px",
                fontWeight: "500",
                color: "#D9D9D9",
              }}
            >
              step 3: press start and pick plankton, fish or whale
            </p>

            <p
              style={{
                marginBottom: "20px",
                fontWeight: "500",
                color: "#D9D9D9",
              }}
            >
              step 4: get matched instantly with another player then sit back
              and watch the match unfold
            </p>

            <p
              style={{
                marginBottom: "30px",
                fontWeight: "500",
                color: "#D9D9D9",
              }}
            >
              step 5: winner receives double their tokens immediately, verified
              on-chain
            </p>

            <p
              style={{
                marginBottom: "30px",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              the platform ensures true{" "}
              <span style={{ color: "rgb(134, 239, 172)" }}>50/50 chance</span>{" "}
              through smart contracts, with no house involvement.
            </p>

            <button
              onClick={onClose}
              style={{
                background: "none",
                border: "1px solid white",
                borderRadius: "4px",
                color: "white",
                padding: "20px 20px",
                cursor: "pointer",
                width: "100%",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              [Start Doubling]
            </button>
          </div>
        ) : (
          <div style={{ color: "white", textAlign: "left", padding: "0 20px" }}>
            <h2
              style={{
                fontSize: "24px",
                marginBottom: "20px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              Why it works
            </h2>

            <p
              style={{
                marginBottom: "25px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              While others are still "trading" meme coins the old fashioned way,
              you've found something revolutionary: instant 2x opportunities.
            </p>

            <p
              style={{
                marginBottom: "20px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              The numbers don't lie:
            </p>

            <div
              style={{
                marginBottom: "25px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              <p style={{ marginBottom: "10px" }}>
                • Chance of becoming a NASA astronaut: a tiny 0.055%
              </p>
              <p style={{ marginBottom: "10px" }}>
                • Chance of making $10k profit trading meme coins: an even
                tinier 0.046%
              </p>
              <p>
                • Your odds of turning 0.3SOL into 30SOL at Double Or Dust: A
                WHOPPING +2,000% HIGHER than both combined!
              </p>
            </div>

            <p
              style={{
                marginBottom: "25px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              You read that right. Your chances of winning BIG with us are TWO
              THOUSAND PERCENT better than becoming an astronaut or hitting it
              big with meme coins put together. We're talking about odds that
              are over 20 TIMES higher than both of those combined!
            </p>

            <p
              style={{
                marginBottom: "25px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              And unlike the murky world of meme coin "trading", every single
              play here is crystal clear: pure 50/50. No jeets. No market
              manipulation. No dumping dev's.
            </p>

            <p
              style={{
                marginBottom: "25px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              Just you vs another player. No house involvement. Pure PvP.
            </p>

            <p
              style={{
                marginBottom: "25px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              Think about this: Why trade with odds worse than becoming an
              astronaut when you could be playing with chances that are
              literally 2,000% better?
            </p>

            <p
              style={{
                marginBottom: "25px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              The math doesn't lie. The choice is yours.
            </p>

            <p
              style={{
                marginBottom: "30px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              p.s You're standing at the frontier of a meme coin revolution -
              welcome to the platform that changes everything.
            </p>

            <button
              onClick={onClose}
              style={{
                background: "none",
                border: "1px solid white",
                borderRadius: "4px",
                color: "white",
                padding: "20px 20px",
                cursor: "pointer",
                width: "100%",
                fontSize: "24px",
                fontWeight: "600",
                marginBottom: "15px",
              }}
            >
              [Start Doubling]
            </button>

            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "white",
                textAlign: "center",
              }}
            >
              *Research Statistics from CN Research
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const ArenaModal = ({ isOpen, onClose, onArenaSelect }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{
          maxWidth: "600px",
          padding: "15px",
          textAlign: "center",
        }}
      >
        <button className="modal-close" onClick={onClose}>
          ×
        </button>

        <h2
          style={{
            fontSize: "28px",
            fontWeight: "700",
            marginBottom: "20px",
            color: "white",
          }}
        >
          choose your arena
        </h2>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
            gap: "20px",
          }}
        >
          <div>
            <p
              style={{
                color: "white",
                marginBottom: "30px",
                fontWeight: "600",
              }}
            >
              plankton
            </p>
            <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0",
                width: "150px",
                height: "150px",
              }}
              onClick={() => onArenaSelect(0.005)}
            >
              <img
                src={planktonLogo}
                alt="Plankton"
                style={{ width: "100%", height: "100%" }}
              />
            </button>
            <p style={{ color: "white", marginTop: "10px", fontWeight: "600" }}>
              0.005 SOL per match
            </p>
          </div>

          <div>
            <p
              style={{
                color: "white",
                marginBottom: "30px",
                fontWeight: "600",
              }}
            >
              fish
            </p>
            <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0",
                width: "150px",
                height: "150px",
              }}
              onClick={() => onArenaSelect(0.5)}
            >
              <img
                src={fishLogo}
                alt="Fish"
                style={{ width: "100%", height: "100%" }}
              />
            </button>
            <p style={{ color: "white", marginTop: "10px", fontWeight: "600" }}>
              0.5 SOL per match
            </p>
          </div>

          <div>
            <p
              style={{
                color: "white",
                marginBottom: "30px",
                fontWeight: "600",
              }}
            >
              whale
            </p>
            <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0",
                width: "150px",
                height: "150px",
              }}
              onClick={() => onArenaSelect(5)}
            >
              <img
                src={whaleLogo}
                alt="Whale"
                style={{ width: "100%", height: "100%" }}
              />
            </button>
            <p style={{ color: "white", marginTop: "10px", fontWeight: "600" }}>
              5 SOL per match
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Main = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [arenaModalOpen, setArenaModalOpen] = useState(false);
  const [currentGameAmount, setCurrentGameAmount] = useState(null);
  const [userGameWallet, setUserGameWallet] = useState(null); // Add this for tracking program wallet
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = clusterApiUrl(network);
  const wallets = [new PhantomWalletAdapter()];

  // Function to create/get program-controlled wallet
  const initializeUserGameWallet = async (mainWalletAddress) => {
    // Logic to create or retrieve program wallet will go here
    // This will be called after wallet connection
  };

  const onArenaSelect = async (amount) => {
    setArenaModalOpen(false);
    setCurrentGameAmount(amount);
    // Pass this to Home component to trigger game start
  };

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <GameProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      modalContent={modalContent}
                      setModalContent={setModalContent}
                      setArenaModalOpen={setArenaModalOpen}
                      onArenaSelect={onArenaSelect}
                      currentGameAmount={currentGameAmount}
                    />
                  }
                />
                <Route
                  path="/token-detail/:tokenAddress"
                  element={<TokenDetail />}
                />
                <Route path="/token-create" element={<TokenCreate />} />
              </Routes>
              <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                content={modalContent}
              />
              <ArenaModal
                isOpen={arenaModalOpen}
                onClose={() => setArenaModalOpen(false)}
                onArenaSelect={onArenaSelect}
              />
            </BrowserRouter>
          </GameProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default Main;
