export const QUOTES = [
    '"your zodiac sign says toxic but my rizz says otherwise" - certified L merchant',
    '"they/them? more like watch me end/them" - based behaviour brad',
    '"they called me a sweat, i took that personally" - tryhard tommy',
    '"grass is political propaganda created by big outdoor" - inside gaming ian',
    '"ratio\'d a verified account, peaked in life" - twitter terrorist tim',
    '"got called toxic in game chat (it was a compliment)" - lobby menace mike',
    '"my mom says i need therapy, i need more RGB" - terminally based brian',
    '"touch grass? i downloaded the grass texture pack" - digital warrior dave',
    '"tried meal prep sunday, ordered uber eats monday" - health journey brad',
    '"wearing black airforces to a job interview (asserting dominance)" - career speedrun any%',
    '"matched with my spotify wrapped, we\'re getting married" - chronically online carl',
    "\"convinced my gym crush i'm rich by wearing airpods (they're fake)\" - finance grindset gary",
    '"their dating profile says 6ft but the grocery store camera says 5\'9" - steve the stalker',
    '"watched fight club twice, personality achieved" - basic chad energy',
    '"bought a gaming chair, still in bronze" - copium overdose',
    '"downloading skill.exe... error 404: skill not found" - perpetual noob james',
    '"throwing for content (i\'m just bad)" - wannabe streamer',
    '"grass is temporary, gaming is forever" - vitamin D deficient derek',
    '"my dad works at nintendo, he\'ll ban your minecraft account" - kid in lobby',
    '"got diamond rank in my dreams last night" - hard stuck silver player',
    '"studying for finals by osmosis (sleeping on my textbook)" - university student life',
    '"downloaded more RAM, now my PC can run Minecraft at 4 FPS" - tech genius dave',
    '"my girlfriend lives in Canada, she just doesn\'t have internet" - that one guy in discord',
    '"mom said it\'s my turn to be the main character" - younger sibling energy',
    '"touched grass once, 0/10 would not recommend" - basement dwelling gary',
    "\"i'm not camping, i'm tactically waiting in this corner\" - definitely not a camper",
    '"trust me bro, I watched a 5-minute YouTube tutorial" - self-proclaimed expert',
    '"may the force be with you" - some starwars guy',
    '"If I win my girl said she would play tonsil tennis with me" - your opponent',
    '"its not about how hard you hit... its about how hard you get hit and keep simping for 3/10 OF bitches" - some old boxing guy lol',
    '"thanks very thanks" - your mom in 6 months when you buy her a porsche',
    '"umm, 1 korma, 2 papadams, and a yoggguurt please" - brad ordering dinner',
    '"may the force be with you" - some starwars guy',
    '"why is it called california-pizza-kitchen if we\'re not in california" - blonde britney',
    '"i ate a lit cigarette and said happy birthday to a kid once bcuz their parents asked for it on cameo" - some crackhead',
    '"why do they call our village jeets?" - raj',
  ];
  