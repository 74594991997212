import logo from './logo.svg';
import dLogo from './assets/images/d-logo.png';
import mainLogo from './assets/images/main-logo.png';
import './App.css';
import Main from './components/Main';

function App() {
  return (
    <div className="App">
      <Main/>
    </div>
  );
}

export default App;
