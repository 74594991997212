// GameContext.jsx (revised)
import React, { createContext, useContext, useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { DODInstructions } from "../solana/program/instructions";
import GameConnection from "./gameconnection";
import { PublicKey } from "@solana/web3.js";
import { createMatch } from "./gameLogic";
import { useAnchorWallet } from "@solana/wallet-adapter-react";

const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const { connected, publicKey } = useWallet();
  const anchorWallet = useAnchorWallet(); // ADDED: Add anchorWallet hook
  const [dodInstance, setDodInstance] = useState(null);
  const [userInitialized, setUserInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gameConnection, setGameConnection] = useState(null);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [matchStatus, setMatchStatus] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (anchorWallet && connected && publicKey) {
      try {
        // Create instance with explicit public key assignment
        const instance = new DODInstructions(anchorWallet);

        setDodInstance(instance);
        // Immediately fetch balance after instance creation
        instance
          .getUserBalance(publicKey)
          .then((newBalance) => setBalance(newBalance))
          .catch(console.error);
        checkUserInitialization(instance);

        // Initialize game connection
        const connection = new GameConnection();
        connection.connect(publicKey.toString(), handleMatchFound);
        setGameConnection(connection);

        return () => {
          connection.disconnect();
        };
      } catch (error) {
        console.error("Failed to initialize DODInstance:", error);
      }
    } else {
      setDodInstance(null);
      setUserInitialized(false);
      if (gameConnection) {
        gameConnection.disconnect();
        setGameConnection(null);
      }
    }
  }, [anchorWallet, connected, publicKey]); // CHANGED: Updated dependencies

  // Add cancelSearch function
  const cancelSearch = () => {
    if (gameConnection) {
      gameConnection.cancelMatch(publicKey.toString());
      setMatchStatus(null);
      setError(null); // Clear any existing errors
      setCurrentMatch(null); // Clear any existing match data
    }
  };

  // In GameContext.jsx, modify the resetGameState function:
  const resetGameState = () => {
    setMatchStatus(null);
    setCurrentMatch(null);
    setError(null);
    setLoading(false);
    if (gameConnection) {
      gameConnection.disconnect();
      // Recreate the connection
      const newConnection = new GameConnection();
      newConnection.connect(publicKey.toString(), handleMatchFound);
      setGameConnection(newConnection);
    }
  };

  const updateBalance = async () => {
    if (dodInstance && connected && publicKey) {
      try {
        const newBalance = await dodInstance.getUserBalance(publicKey);
        setBalance(newBalance);
        return newBalance;
      } catch (error) {
        console.error("Failed to update balance:", error);
        throw error;
      }
    }
  };

  const checkUserInitialization = async (instance) => {
    try {
      setLoading(true);
      setError(null);

      const [userPDA] = instance.getUserPDA(publicKey);
      try {
        await instance.program.account.userAccount.fetch(userPDA);
        setUserInitialized(true);
      } catch {
        // User not initialized yet - will be initialized on game start
        setUserInitialized(false);
      }
    } catch (err) {
      console.error("Failed to check user initialization:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const initializeUserIfNeeded = async () => {
    if (!userInitialized && dodInstance) {
      try {
        setLoading(true);
        await dodInstance.initializeUser();
        setUserInitialized(true);
      } catch (err) {
        console.error("Failed to initialize user:", err);
        setError(err.message);
        throw err;
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMatchFound = async (match) => {
    try {
      setLoading(true);
      setTransactionStatus("pending");
      setMatchStatus("found");

      // Determine player roles
      const isPlayerA = match.player1 === publicKey.toString();
      const opponentPubkey = new PublicKey(
        isPlayerA ? match.player2 : match.player1
      );

      // Execute match transaction
      const matchResult = await dodInstance.executeMatch(
        opponentPubkey,
        getBetAmountFromTier(match.betTier),
        dodInstance.generateVRFSeed()
      );

      // Determine winner based on balance changes
      const didCurrentPlayerWin =
        (isPlayerA && matchResult.winner === "player1") ||
        (!isPlayerA && matchResult.winner === "player2");

      // Only create and set currentMatch after transaction is confirmed
      if (matchResult) {
        setTransactionStatus("confirmed");
        const gameMatch = await createMatch(match.betTier, {
          matchId: match.matchId,
          isPlayerA,
          opponent: opponentPubkey.toString(),
          winner: didCurrentPlayerWin ? "A" : "B",
        });

        if (gameMatch) {
          setCurrentMatch({
            ...gameMatch,
            playerRole: isPlayerA ? "A" : "B",
          });
          setMatchStatus(null);
        }
      }
    } catch (error) {
      console.error("Match execution failed:", error);
      setTransactionStatus("failed");
      setError(error.message);
      setMatchStatus(null);
    } finally {
      setLoading(false);
    }
  };

  const searchForMatch = async (tier) => {
    if (!gameConnection || !publicKey || !dodInstance) {
      setError("Missing required connection. Please reconnect wallet.");
      return;
    }

    try {
      setLoading(true);
      setMatchStatus("searching");

      // Check user balance before matching
      const balance = await dodInstance.getUserBalance(publicKey);
      const betAmount = getBetAmountFromTier(tier);

      if (balance < betAmount) {
        throw new Error("Insufficient balance for selected arena");
      }

      gameConnection.searchMatch(publicKey.toString(), tier);
    } catch (error) {
      console.error("Match search failed:", error);
      setError(error.message);
      setMatchStatus(null);
    } finally {
      setLoading(false);
    }
  };

  const getBetAmountFromTier = (tier) => {
    const amounts = {
      plankton: 0.005,
      fish: 0.5,
      whale: 5,
    };
    return amounts[tier];
  };

  return (
    <GameContext.Provider
      value={{
        dodInstance,
        userInitialized,
        loading,
        error,
        connected,
        publicKey,
        initializeUserIfNeeded,
        searchForMatch,
        currentMatch,
        balance,
        updateBalance,
        matchStatus,
        cancelSearch,
        resetGameState, // Added this new state
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);
