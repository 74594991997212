// components/WalletActionsModal.jsx
import React, { useState } from "react";
import { useGame } from "./gameContext";
import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";

const WalletActionsModal = ({ isOpen, onClose }) => {
  const anchorWallet = useAnchorWallet(); // Add this
  const [amount, setAmount] = useState("");
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false); // Add this
  const { dodInstance, updateBalance, balance } = useGame();
  const { publicKey, connected } = useWallet(); // Add this

  if (!isOpen) return null;

  const handleAction = async () => {
    if (!connected || !publicKey) {
      setError("Please connect your wallet first");
      return;
    }

    if (!dodInstance) {
      setError("Game instance not initialized");
      return;
    }

    // Only validate amount for deposits, not withdrawals
    if (!isWithdrawing && (!amount || isNaN(amount) || amount <= 0)) {
      setError("Please enter a valid amount");
      return;
    }

    const amountNumber = parseFloat(amount);
    // Add maximum amount validation
    if (amountNumber * anchor.web3.LAMPORTS_PER_SOL > Number.MAX_SAFE_INTEGER) {
      setError("Amount too large");
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      const lamports = new anchor.BN(
        Math.round(amountNumber * anchor.web3.LAMPORTS_PER_SOL)
      );

      if (isWithdrawing) {
        await dodInstance.withdraw();
      } else {
        // Ensure the lamports value is valid before sending
        if (lamports.isNeg() || lamports.isZero()) {
          throw new Error("Invalid amount");
        }
        await dodInstance.initializeAndDeposit(lamports);
      }

      await updateBalance();
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        onClose();
      }, 1500);
      setAmount("");
    } catch (error) {
      console.error(
        `${isWithdrawing ? "Withdrawal" : "Deposit"} failed:`,
        error
      );
      setError(error.message || "Transaction failed");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content wallet-actions-modal"
        onClick={(e) => e.stopPropagation()}
        style={{ maxWidth: "400px" }}
      >
        <button className="modal-close" onClick={onClose}>
          ×
        </button>
        <div style={{ padding: "20px", color: "white" }}>
          <h2 style={{ marginBottom: "20px", textAlign: "center" }}>
            {isWithdrawing ? "Withdraw Funds" : "Deposit Funds"}
          </h2>

          <div style={{ marginBottom: "20px" }}>
            <button
              onClick={() => {
                setIsWithdrawing(false);
                setError(null);
              }}
              style={{
                background: !isWithdrawing ? "#4CAF50" : "none",
                border: "1px solid white",
                color: "white",
                padding: "10px",
                marginRight: "10px",
                width: "45%",
              }}
            >
              Deposit
            </button>
            <button
              onClick={() => {
                setIsWithdrawing(true);
                setError(null);
              }}
              style={{
                background: isWithdrawing ? "#f44336" : "none",
                border: "1px solid white",
                color: "white",
                padding: "10px",
                width: "45%",
              }}
            >
              Withdraw
            </button>
          </div>

          {isWithdrawing && (
            <div
              style={{
                marginBottom: "20px",
                textAlign: "center",
                padding: "10px",
                background: "rgba(134, 239, 172, 0.1)",
                borderRadius: "4px",
              }}
            >
              Available: {(balance / anchor.web3.LAMPORTS_PER_SOL).toFixed(3)}{" "}
              SOL
            </div>
          )}

          {!isWithdrawing && (
            <input
              type="number"
              value={amount}
              onChange={(e) => {
                // Limit to 9 decimal places (Solana's precision)
                const value = e.target.value;
                if (value === "" || /^\d*\.?\d{0,9}$/.test(value)) {
                  setAmount(value);
                  setError(null);
                }
              }}
              step="0.000000001" // Smallest possible SOL amount
              min="0"
              placeholder="Enter amount in SOL"
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "20px",
                background: "none",
                border: "1px solid white",
                color: "white",
              }}
              disabled={processing}
            />
          )}

          {error && (
            <div
              style={{
                color: "#ff4444",
                marginBottom: "15px",
                textAlign: "center",
              }}
            >
              {error}
            </div>
          )}

          <button
            onClick={handleAction}
            disabled={processing || (!isWithdrawing && !amount)}
            style={{
              width: "100%",
              padding: "10px",
              background: "none",
              border: "1px solid white",
              color: "white",
              cursor: processing ? "not-allowed" : "pointer",
              opacity: processing ? 0.7 : 1,
            }}
          >
            {processing
              ? "Processing..."
              : isWithdrawing
              ? "Withdraw All"
              : "Deposit SOL"}
          </button>

          {showSuccess && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "rgba(134, 239, 172, 0.9)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "24px",
              }}
            >
              Transaction Successful!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletActionsModal;
