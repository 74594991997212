// gameLogic.js
function importAll(r) {
  let videos = {};
  r.keys().forEach((item) => {
    videos[item.replace("./", "")] = r(item);
  });
  return videos;
}
// Import all MP4 files from the videos directory
const videoFiles = importAll(
  require.context("../assets/videos", false, /\.mp4$/)
);

// Add the console.log here to verify video imports
console.log("Available videos:", videoFiles);

// Sample video data with clear win conditions
const videos = [
  {
    url: videoFiles["deeneygoal.mp4"],
    headline: "Which team scores first?",
    outcomeA: "Blue",
    outcomeB: "Yellow",
    outcome: "B",
  },
  {
    url: videoFiles["nipple.mp4"],
    headline: "How many nipples does he have?",
    outcomeA: "Other than 2",
    outcomeB: "2",
    outcome: "A",
  },
  // Add more videos with similar structure
];

export const createMatch = async (amount, matchData = null) => {
  // If matchData exists, use it for deterministic outcome
  // Otherwise fall back to random for testing
  const winner = matchData
    ? matchData.isPlayerA
      ? "A"
      : "B"
    : Math.random() > 0.5
    ? "A"
    : "B";

  // Select random video that matches the winner
  const matchingVideos = videos.filter((v) => v.outcome === winner);
  const selectedVideo =
    matchingVideos[Math.floor(Math.random() * matchingVideos.length)];

  return {
    id: matchData?.matchId || Math.random().toString(),
    amount,
    winner,
    videoUrl: selectedVideo.url,
    headline: selectedVideo.headline,
    playerAChoice: selectedVideo.outcomeA,
    playerBChoice: selectedVideo.outcomeB,
    opponent: matchData?.opponent,
  };
};

// Mock waiting period with countdown
export const waitForOpponent = async () => {
  return new Promise((resolve) => setTimeout(resolve, 2000)); // 2 second wait
};

// Determine if player is A or B
export const determinePlayerRole = () => {
  return Math.random() > 0.5 ? "A" : "B";
};

// Mock match execution
export const executeMatch = async (match) => {
  return match.winner; // Return predetermined winner
};
