// audioManager.js
import countdownTick from "../assets/sounds/tick.mp3";
import countdownFinal from "../assets/sounds/finaltick.mp3";
import doubledSound from "../assets/sounds/doubled.mp3";
import dustSound from "../assets/sounds/dust.mp3";

class AudioManager {
  constructor() {
    this.enabled = true;
    this.sounds = {
      countdownTick: new Audio(countdownTick),
      countdownFinal: new Audio(countdownFinal),
      doubled: new Audio(doubledSound),
      dust: new Audio(dustSound),
    };

    // Pre-load and optimize sounds
    Object.values(this.sounds).forEach((audio) => {
      audio.load();
      // Set optimal values for game sounds
      audio.volume = 0.99; // Not too loud
      audio.preload = "auto"; // Ensure sounds are ready
    });
  }

  setEnabled(enabled) {
    this.enabled = enabled;
  }

  play(soundName) {
    if (!this.enabled) return;

    const sound = this.sounds[soundName];
    if (sound) {
      sound.currentTime = 0; // Reset sound to start
      sound.play().catch((err) => console.log("Audio play failed:", err));
    }
  }
}

export const audioManager = new AudioManager();
