// react and routing
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//assets
import dLogo from "../assets/images/d-logo.png";
import mainLogo from "../assets/images/main-logo.png";

//solana
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";

//components and context
import { useGame } from "./gameContext"; // Add this import
import GameDisplay from "./GameDisplay";
import Balance from "./userbalance";
import WalletActionsModal from "./WalletActionsModal";
import UserBalance from "./userbalance";

//game logic
import {
  createMatch,
  waitForOpponent,
  determinePlayerRole,
  executeMatch,
} from "./gameLogic";

const Home = ({
  modalOpen,
  setModalOpen,
  modalContent,
  setModalContent,
  setArenaModalOpen,
  onArenaSelect,
  currentGameAmount, // Make sure to add this prop
}) => {
  const {
    dodInstance,
    userInitialized,
    loading,
    error,
    initializeUserIfNeeded,
    searchForMatch, // Add this
    currentMatch, // Add this
    resetGameState,
  } = useGame();
  const { connected, publicKey } = useWallet();
  const [isGameActive, setIsGameActive] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  // Add the useEffect here, right after state declarations
  useEffect(() => {
    if (currentGameAmount) {
      // Changed condition to check currentGameAmount
      handleArenaSelection(currentGameAmount);
    }
  }, [currentGameAmount]);

  const handleModalOpen = (e, type) => {
    e.preventDefault();
    setModalContent(type);
    setModalOpen(true);
  };

  const handleStartClick = async () => {
    // Force reset state before starting
    //resetGameState();
    setIsGameActive(false);

    if (!connected) {
      document.querySelector(".wallet-adapter-button").click();
      const checkConnection = setInterval(() => {
        if (connected) {
          clearInterval(checkConnection);
          checkUserBalance();
        }
      }, 500);
    } else {
      checkUserBalance();
    }
  };

  // Add this new function in Home.jsx
  const checkUserBalance = async () => {
    console.log("checkUserBalance called", {
      dodInstance: !!dodInstance,
      publicKey: publicKey?.toString(),
    });

    if (!dodInstance) {
      console.log("No dodInstance available");
      return;
    }

    try {
      let balance = 0;
      try {
        const [userPDA] = dodInstance.getUserPDA(publicKey);
        // Try to fetch balance, but don't throw if account doesn't exist
        try {
          const userAccount =
            await dodInstance.program.account.userAccount.fetch(userPDA);
          balance = userAccount.balance.toNumber();
        } catch (error) {
          // If account doesn't exist, balance remains 0
          console.log("User account doesn't exist yet");
        }
      } catch (error) {
        console.error("Error checking balance:", error);
      }

      // Either balance is 0 or account doesn't exist - both cases should show deposit modal
      if (balance <= 0) {
        console.log("Opening wallet modal for deposit");
        setWalletModalOpen(true);
      } else {
        console.log("Opening arena modal");
        setArenaModalOpen(true);
      }
    } catch (error) {
      console.error("Failed in checkUserBalance:", error);
      // Even if something goes wrong, we should default to showing deposit modal
      setWalletModalOpen(true);
    }
  };

  const handleArenaSelection = async (amount) => {
    try {
      setSelectedAmount(amount);
      setArenaModalOpen(false);

      // Convert amount to tier
      const tier = getTierFromAmount(amount);

      setIsGameActive(true);
      // Search for match
      await searchForMatch(tier);
    } catch (error) {
      console.error("Arena selection failed:", error);
      setIsGameActive(false);
    }
  };

  const getTierFromAmount = (amount) => {
    switch (amount) {
      case 0.005:
        return "plankton";
      case 0.5:
        return "fish";
      case 5:
        return "whale";
      default:
        throw new Error("Invalid amount");
    }
  };

  const handleMatchComplete = () => {
    setIsGameActive(false);
    setVideoPlaying(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Initializing...</p>
        </div>
      ) : error ? (
        <div className="error-message">
          <p>Error: {error}</p>
          <button onClick={() => window.location.reload()}>Retry</button>
        </div>
      ) : (
        <div className="app">
          <nav className="navbar">
            <div className="nav-left">
              <img src={dLogo} alt="D Logo" className="nav-logo" />
              <div className="nav-links">
                <a
                  href="#"
                  className="nav-link"
                  onClick={(e) => handleModalOpen(e, "how")}
                >
                  [how it works]
                </a>
                <a
                  href="#"
                  className="nav-link"
                  onClick={(e) => handleModalOpen(e, "why")}
                >
                  [why it works]
                </a>
                <a href="#" className="nav-link">
                  [support]
                </a>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {connected && (
                <button
                  onClick={() => setWalletModalOpen(true)}
                  className="nav-button"
                  style={{
                    background: "none",

                    padding: "8px 16px",
                    cursor: "pointer",
                  }}
                >
                  [deposit/withdraw]
                </button>
              )}
              <WalletMultiButton className="nav-button" />
            </div>
          </nav>

          <div className="card-container">
            <img
              src={mainLogo}
              alt="dod main logo"
              className="start-new-image"
            />
            <UserBalance />
            <h3 className="start-game" onClick={handleStartClick}>
              [press here to start]
            </h3>

            {isGameActive ? (
              <GameDisplay
                currentMatch={currentMatch}
                onMatchComplete={handleMatchComplete}
                isGameActive={isGameActive}
              />
            ) : null}
          </div>
        </div>
      )}
      <WalletActionsModal
        isOpen={walletModalOpen}
        onClose={() => setWalletModalOpen(false)}
      />
    </>
  );
};

export default Home;
