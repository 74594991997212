// components/userbalance.jsx
import React, { useState, useRef, useEffect } from "react";
import { useGame } from "./gameContext";
import { useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";

const UserBalance = () => {
  const { dodInstance, balance, updateBalance } = useGame();
  const { connected } = useWallet();

  // Add animation class when balance changes
  const [animating, setAnimating] = useState(false);
  const previousBalance = useRef(balance);

  useEffect(() => {
    if (previousBalance.current !== balance) {
      setAnimating(true);
      setTimeout(() => setAnimating(false), 1000);
      previousBalance.current = balance;
    }
  }, [balance]);

  if (!connected) return null;

  return (
    <div className={`balance-display ${animating ? "balance-update" : ""}`}>
      <p>Balance: {(balance / anchor.web3.LAMPORTS_PER_SOL).toFixed(3)} SOL</p>
    </div>
  );
};

export default UserBalance;
