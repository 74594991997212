class GameConnection {
    constructor() {
      this.ws = null;
      this.matchCallback = null;
    }
  
    connect(walletAddress, onMatch) {
      this.matchCallback = onMatch;
      this.walletAddress = walletAddress; // Store wallet address for reference
  
      const wsUrl = "wss://doubleordust.fun/ws";
  
      this.ws = new WebSocket(wsUrl);
  
      this.ws.onopen = () => {
        if (this.ws) {
          console.log("WebSocket connected, sending CONNECT message");
          this.ws.send(
            JSON.stringify({
              type: "CONNECT",
              walletAddress,
            })
          );
        }
      };
  
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("WebSocket message received:", data);
  
        switch (data.type) {
          case "SEARCHING":
            console.log("Search state received");
            if (this.matchCallback) {
              this.matchCallback({ type: "searching" });
            }
            break;
  
          case "MATCH_FOUND":
            console.log("Match found:", data.match);
            if (this.matchCallback && data.match) {
              // Verify this player is part of the match
              if (
                data.match.player1 === this.walletAddress ||
                data.match.player2 === this.walletAddress
              ) {
                this.matchCallback(data.match);
              } else {
                console.error("Received match data for wrong player");
              }
            } else {
              console.error("Invalid match data received:", data);
            }
            break;
  
          default:
            console.log("Unknown message type:", data.type);
        }
      };
  
      this.ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
  
      this.ws.onclose = () => {
        console.log("WebSocket connection closed");
        // Optional: implement reconnection logic here if needed
      };
    }
  
    searchMatch(walletAddress, betTier) {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        console.log("Sending search request:", { walletAddress, betTier });
        this.ws.send(
          JSON.stringify({
            type: "SEARCH_MATCH",
            walletAddress,
            betTier,
          })
        );
      } else {
        console.error(
          "WebSocket not ready, connection state:",
          this.ws?.readyState
        );
      }
    }
  
    cancelMatch(walletAddress) {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        console.log("Sending cancel request");
        this.ws.send(
          JSON.stringify({
            type: "CANCEL_MATCH",
            walletAddress,
          })
        );
      }
    }
  
    disconnect() {
      if (this.ws) {
        console.log("Disconnecting WebSocket");
        this.ws.close();
        this.ws = null;
      }
    }
  }
  
  export default GameConnection;
  
